
import { defineComponent } from "vue";
import { ElLoading } from "element-plus";
import ReferAFriend from "./MyAccount/ReferAFriend.vue";
import MyProfile from "./MyAccount/MyProfile.vue";
import MyCompany from "./MyAccount/MyCompany.vue";
import PlanAndBilling from "./MyAccount/PlanAndBilling.vue";
// import _ from "lodash";
// import helpers from "@/helpers/global";
// import {
//   getStorage,
//   ref,
//   uploadString,
//   getDownloadURL,
// } from "firebase/storage";

let loadingEl: any;

export default defineComponent({
  name: "MyAccount",
  components: {
    ReferAFriend,
    MyProfile,
    MyCompany,
    PlanAndBilling,
  },
  async created() {
    // @see src\views\MyAccount.vue
    // await this.$store.dispatch("user/loadCurrentUser", null, true);

    // Add Omise.js
    const script = document.createElement("script");
    script.setAttribute("src", "https://cdn.omise.co/omise.js");
    document.body.appendChild(script);
  },
  computed: {
    // yourPlan() {
    //   const plan = "Pay as you go";
    //   return plan;
    // },
    paymentEnabled() {
      return process.env.VUE_APP_PAYMENT_ENABLED === "on";
    },
    totalCredit() {
      return 11 + " credit remaining";
    },
    user() {
      return this.$store.getters["user/user"];
    },
    // quota() {
    //   return this.$store.getters["quota/data"];
    // },
    companyLogoUrl(): string {
      return this.$store.getters["user/companyLogoUrl"];
    },
    loading() {
      return this.$store.getters["payment/loading"];
    },
    tabParam(): any {
      return this.$router.currentRoute.value.params.tab;
    },
    paymentError() {
      return this.$store.getters["payment/error"];
    },
    couponError(): any {
      return this.$store.getters["coupon/error"];
    },
    couponLoading(): any {
      return this.$store.getters["coupon/loading"];
    },
  },
  watch: {
    // quota(value: any) {
    //   if (value) {
    //     this.updateQuotaDisplay();
    //   }
    // },
    paymentError(value) {
      if (value) {
        console.log(value, "payment error");
      }
    },
    // @fix if url is changed from credit menu
    tabParam(value) {
      if (!value) return;
      switch (value) {
        case "my-profile":
          this.activeTab = "My_Profile";
          break;
        case "my-company":
          this.activeTab = "My_Company";
          break;
        case "plan-billing":
          this.activeTab = "Plan_Billing";
          break;
        case "refer-a-friend":
          this.activeTab = "Refer_a_friend";
          break;
      }
    },
    activeTab(tab: string) {
      if (tab) {
        let paramsTab = "";
        switch (tab) {
          case "My_Profile":
            paramsTab = "my-profile";
            break;
          case "My_Company":
            paramsTab = "my-company";
            break;
          case "Plan_Billing":
            paramsTab = "plan-billing";
            break;
          case "Refer_a_friend":
            paramsTab = "refer-a-friend";
            break;
        }
        this.$router.push({
          name: "MyAccountWithTab",
          params: { tab: paramsTab },
        });
      }
    },
    loading(value: any) {
      if (value) {
        loadingEl = ElLoading.service({
          lock: true,
          text: "Login..",
          background: "#ffffff90",
        });
      } else {
        loadingEl.close();
      }
    },
  },
  async mounted() {
    if (!this.$store.getters["user/user"]) {
      await this.$store.dispatch("user/loadCurrentUser");
    }
    if (this.$store.getters["user/user"]) {
      this.color = this.$store.getters["user/user"].color;
    }

    // this.updateQuotaDisplay();

    const tab = this.$router.currentRoute.value.params.tab || false;
    if (tab) {
      switch (tab) {
        case "my-profile":
          this.activeTab = "My_Profile";
          break;
        case "my-company":
          this.activeTab = "My_Company";
          break;
        case "plan-billing":
          this.activeTab = "Plan_Billing";
          break;
        case "refer-a-friend":
          this.activeTab = "Refer_a_friend";
          break;
      }
    }
  },
  data() {
    return {
      activeTab: "My_Profile", // My_Profile, My_Company, Plan_Billing
      // yourPlan: "Free Plan",
      // credit: 0,
      // creditRemaining: 5,
      // creditTotal: 5,
      // percentage: 0,
      // couponCode: "",
      // redeemCouponFailed: false,
      userDetail: {
        id: "",
        firstname: "",
        lastname: "",
        company_name: "",
        company_color: "#000",
        logo: "",
        // link of image
        company_logo: "",
        imageFile: "",
      },
      // loadingImageFile: false,
      // companyUploadedFile: null,
      // companyUploadedFileDataUrl: "",
      color: "",
      // monthlySubscriptionModalStatus: false,
      // payAsYouGoModalStatus: false,
      // couponModalStatus: true,
      // listItemPay: [
      //   {
      //     name: "Unlimited_assessment_variations",
      //   },
      //   {
      //     name: "Access_to_all_available",
      //   },
      //   {
      //     name: "Group_analytics",
      //   },
      //   {
      //     name: "Bulk_invites",
      //   },
      //   {
      //     name: "Custom_branding",
      //   },
      //   {
      //     name: "Email_Support",
      //   },
      // ],
    };
  },
  methods: {
    // isLightColor(): any {
    //   const user = this.$store.getters["user/user"] || {};
    //   const color = user.color || "#000";
    //   return helpers.isLightColor(color);
    // },
    // openReferAFriendModdal() {
    //   // @see https://trello.com/c/CpgNhSFa/1544-plans-billing-as-a-admin-i-want-to-be-redirected-to-the-refer-a-friend-tab-while-payments-are-not-working-so-that-i-can-continue
    //   const $t = (this as any).$t;
    //   const prefix = "myAccount.paymentInteruptPopup.";
    //   const title = $t(prefix + "title");
    //   const body = $t(prefix + "body");
    //   ElMessageBox.confirm(body, title, {
    //     confirmButtonText: $t(prefix + "confirmButtonText"),
    //     cancelButtonText: $t(prefix + "cancelButtonText"),
    //     type: "info",
    //   }).then(() => {
    //     this.activeTab = "Refer_a_friend";
    //   });
    // },
    // openpayAsYouGoModalStatusDetail() {
    //   // console.log("openpayAsYouGoModalStatusDetail()");
    //   // this.payAsYouGoModalStatus = true;
    //   // this.couponModalStatus = true;
    //   this.openReferAFriendModdal();
    // },
    // openmonthlySubscriptionModalStatusDetail() {
    //   this.monthlySubscriptionModalStatus = true;
    // },
    // async openOmiseModal(req: {
    //   sku: string;
    //   price: number;
    //   callback: () => Promise<string>;
    // }) {
    //   // console.log("openOmiseModal()");
    //   // console.log("debug...");
    //   // if (req.sku) return;
    //   // Omise
    //   // @see https://www.omise.co/omise-js#using-javascript
    //   let OmiseCard: any;
    //   for (const k in window) {
    //     if (k == "OmiseCard") {
    //       OmiseCard = window[k];
    //     }
    //   }
    //   if (!OmiseCard) {
    //     console.log("Error: Undefined OmiseCard");
    //     return;
    //   }
    //   const amount = req.price;
    //   const currency = helpers.getCurrency();
    //   const description = helpers.getDescriptionBySku(req.sku);
    //   OmiseCard.configure({
    //     publicKey: process.env.VUE_APP_OMISE_PUBLIC_KEY,
    //   });
    //   // let Omise: any;
    //   // for (const k in window) {
    //   //   if (k == "Omise") {
    //   //     Omise = window[k];
    //   //   }
    //   // }
    //   // Omise.createToken(
    //   //   "card",
    //   //   {
    //   //     expiration_month: 2,
    //   //     expiration_year: 2022,
    //   //     name: "Somchai Prasert",
    //   //     number: "4242424242424242",
    //   //     security_code: "123",
    //   //     street1: "476 Fifth Avenue",
    //   //     city: "New York",
    //   //     state: "NY",
    //   //     postal_code: "10320",
    //   //     country: "US",
    //   //   },
    //   //   (statusCode: any, response: any) => {
    //   //     console.log(statusCode);
    //   //     console.log(response);
    //   //   }
    //   // );
    //   // if (OmiseCard) return;
    //   // console.log("OmiseCard.open()");
    //   OmiseCard.open({
    //     amount: amount,
    //     currency: currency,
    //     defaultPaymentMethod: "credit_card",
    //     onCreateTokenSuccess: (nonce: string) => {
    //       return (async () => {
    //         const form: any = document.querySelector("#checkoutForm");
    //         form.action = process.env.VUE_APP_OMISE_API_URL + "/omise/charge";
    //         if (!form) return;
    //         if (nonce.startsWith("tokn_")) {
    //           form.omiseToken.value = nonce;
    //         } else {
    //           form.omiseSource.value = nonce;
    //         }
    //         form.amount.value = amount;
    //         form.currency.value = currency;
    //         form.description.value = description;
    //         // id of reference
    //         this.$store.commit("payment/loading", true, { root: true });
    //         const id = await req.callback();
    //         form.id.value = id;
    //         form.redirectUri.value = window.location.href;
    //         // console.log(form.redirectUri.value, "form.redirectUri.value ");
    //         form.submit();
    //       })();
    //     },
    //   });
    // },
    // async payAsYouGo(sku: string) {
    //   // console.log(`payAsYouGo(${sku})`);
    //   this.yourPlan = "Pay as you go";
    //   this.payAsYouGoModalStatus = false;
    //   const callback = async (): Promise<string> => {
    //     const request = {
    //       sku: sku,
    //     };
    //     // this.$store.commit("payment/loading", true, { root: true });
    //     await this.$store.dispatch("payment/payAsYouGo", request, {
    //       root: true,
    //     });
    //     // this.$store.commit("payment/loading", false, { root: true });
    //     const lastInsertId = this.$store.getters["payment/lastInsertId"];
    //     return lastInsertId;
    //   };
    //   const price = helpers.getPriceBySku(sku, "omise");
    //   this.openOmiseModal({
    //     sku: sku,
    //     price: price,
    //     callback: callback,
    //   });
    // },
    // async cancelSubscribe() {
    //   // console.log("cancelSubscribe()");
    //   await this.$store.dispatch("payment/cancelSubscription", null, {
    //     root: true,
    //   });
    //   // this.yourPlan = "Pay as you go";
    // },
    // subscribeRecuring(sku: string, success?: any) {
    //   // @todo modal for annual: 25% discount
    //   // const sku = "MONTHLY_1_4900";
    //   const callback = async (): Promise<string> => {
    //     if (this.quota) {
    //       const plan = this.quota.plan;
    //       if (plan == "monthly" || plan == "annual") {
    //         // await this.cancelSubscribe();
    //       }
    //     }
    //     const request = {
    //       sku: sku,
    //     };
    //     await this.$store.dispatch("payment/payMonthly", request, {
    //       root: true,
    //     });
    //     const lastInsertId = this.$store.getters["payment/lastInsertId"];
    //     if (typeof success == "function") {
    //       success();
    //     }
    //     return lastInsertId;
    //   };
    //   const price = helpers.getPriceBySku(sku, "omise");
    //   this.openOmiseModal({
    //     sku: sku,
    //     price: price,
    //     callback: callback,
    //   });
    // },
    // subscribeMonthly() {
    //   // console.log("subscribeMonthly()");
    //   this.monthlySubscriptionModalStatus = false;
    //   const sku = "MONTHLY_1_4900";
    //   this.subscribeRecuring(sku, () => {
    //     this.yourPlan = "Monthly Subscription";
    //   });
    // },
    // subscribeAnnual() {
    //   // console.log("subscribeAnnual()");
    //   this.monthlySubscriptionModalStatus = false;
    //   const sku = "YEARLY_1_44100";
    //   this.subscribeRecuring(sku, () => {
    //     this.yourPlan = "Annual Subscription";
    //   });
    // },
    // async redeemCoupon() {
    //   this.redeemCouponFailed = false;
    //   if (this.couponCode) {
    //     const title = `${(this as any).$t(
    //       "myAccount.planAndBill.credits_redeemed_1"
    //     )} ${10} ${(this as any).$t(
    //       "myAccount.planAndBill.credits_redeemed_2"
    //     )}`;
    //     ElNotification({
    //       title: title,
    //       message: (this as any).$t(
    //         "myAccount.planAndBill.You_have_successfully"
    //       ),
    //       type: "success",
    //     });
    //   } else {
    //     // TODO: show error message
    //     this.redeemCouponFailed = true;
    //     ElNotification({
    //       title: (this as any).$t("myAccount.planAndBill.Failed_redeem"),
    //       message: (this as any).$t("coupon.error_messages.message_1"),
    //       type: "error",
    //     });
    //   }
    //   // await this.$store.dispatch("coupon/redeem", this.couponCode, {
    //   //   root: true,
    //   // });
    //   // if (this.$store.getters["coupon/error"]) {
    //   //   return;
    //   // }
    //   // this.couponCode = "";
    //   // this.couponModalStatus = false;
    // },
    // updateQuotaDisplay() {
    //   const value = this.quota;
    //   if (value) {
    //     switch (value.plan) {
    //       case "payg":
    //         this.yourPlan = "Pay as you go";
    //         break;
    //       case "monthly":
    //         this.yourPlan = "Monthly Subscription";
    //         break;
    //       case "annual":
    //         this.yourPlan = "Annual Subscription";
    //         break;
    //       case "free":
    //       default:
    //         this.yourPlan = "Free Plan";
    //         break;
    //     }
    //     this.credit = value.quota_used;
    //     this.creditRemaining = value.quota_remaining;
    //     this.creditTotal = value.quota_total;
    //     // percentage
    //     this.percentage = 100;
    //     if (value.plan != "monthly" && value.plan != "annual") {
    //       this.percentage = Math.round(
    //         (value.quota_remaining / value.quota_total) * 100
    //       );
    //     }
    //   } else {
    //     this.yourPlan = "Free Plan";
    //     this.percentage = 0;
    //   }
    // },
  },
});
