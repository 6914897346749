
import { defineComponent } from "vue";
import { ElMessage, ElLoading } from "element-plus";
import Loading from "@/components/Basic/Loading.vue";
import _ from "lodash";
import helpers from "@/helpers/global";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
} from "firebase/storage";

let loadingEl: any;

export default defineComponent({
  name: "MyCompany",
  components: {
    Loading,
  },
  computed: {
    paymentEnabled() {
      return process.env.VUE_APP_PAYMENT_ENABLED === "on";
    },
    user() {
      return this.$store.getters["user/user"];
    },
    companyLogoUrl() {
      return this.$store.getters["user/companyLogoUrl"];
    },
    loading() {
      return this.$store.getters["payment/loading"];
    },
  },
  watch: {
    loading(value: any) {
      if (value) {
        loadingEl = ElLoading.service({
          lock: true,
          text: "Login..",
          background: "#ffffff90",
        });
      } else {
        loadingEl.close();
      }
    },
  },
  async mounted() {
    if (!this.$store.getters["user/user"]) {
      await this.$store.dispatch("user/loadCurrentUser");
    }
    if (this.$store.getters["user/user"]) {
      this.color = this.$store.getters["user/user"].color;
    }
  },
  data() {
    return {
      userDetail: {
        id: "",
        firstname: "",
        lastname: "",
        company_name: "",
        company_color: "#000",
        logo: "",
        // link of image
        company_logo: "",
        imageFile: "",
      },
      loadingImageFile: false,
      companyUploadedFile: null,
      companyUploadedFileDataUrl: "",
      color: "",
    };
  },
  methods: {
    async saveMyProfile() {
      const user = this.$store.getters["user/user"] || {};
      user.color = this.color;
      let userDetails: any = _.pickBy(user, (value, key) => {
        const pickedKeys = [
          "email",
          "firstName",
          "lastName",
          "companyName",
          "color",
        ];
        const found = _.indexOf(pickedKeys, key) !== -1;
        const isEmpty = !value;
        return found && !isEmpty;
      });

      // @todo check is it dirty

      // upload image before saveUser
      const file: any = this.companyUploadedFile;
      const dataUrl = this.companyUploadedFileDataUrl;
      if (file && dataUrl) {
        const storage = getStorage();
        const email = helpers.emailKey(user.email);
        const prefix = "company";
        const guid = helpers.guid();

        // @todo beautiful filename
        let filename = file.name;
        if (filename.indexOf(".") !== -1) {
          const chunks = filename.split(".");
          const ext = chunks.pop();
          filename = chunks.join(".") + "-" + guid + "." + ext;
        }
        const path = `images/users/${email}/${prefix}-${filename}`;
        const storageRef = ref(storage, path);
        uploadString(storageRef, dataUrl, "data_url").then(async (snapshot) => {
          getDownloadURL(snapshot.ref).then(async (url) => {
            //userDetails.companyLogoUrl = snapshot.metadata.fullPath;
            userDetails.companyLogoUrl = url;
            await this.$store.dispatch("user/saveUser", userDetails, {
              root: true,
            });
          });
          ElMessage({
            message: (this as any).$t("popup.message.message_18"),
            type: "success",
          });
        });
      } else {
        await this.$store.dispatch("user/saveUser", userDetails, {
          root: true,
        });
        ElMessage({
          message: (this as any).$t("popup.message.message_18"),
          type: "success",
        });
      }
    },
    async saveMyCompany() {
      await this.saveMyProfile();
    },
    handleAvatarSuccess(res: any) {
      this.userDetail.imageFile = URL.createObjectURL(res.file);

      const reader = new FileReader();
      reader.addEventListener("load", (/*event: ProgressEvent*/) => {
        this.companyUploadedFile = res.file;
        this.companyUploadedFileDataUrl = String(reader.result);
        // this.loadingImageFile = false;
      });
      reader.readAsDataURL(res.file);

      setTimeout(() => {
        this.loadingImageFile = false;
      }, 300);
    },
    beforeAvatarUpload(file: any) {
      const MAX_FILE_SIZE = 5;
      this.loadingImageFile = true;
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < MAX_FILE_SIZE;

      if (!isJPG) {
        this.loadingImageFile = false;
        ElMessage.error((this as any).$t("popup.message.message_5e"));
      }
      if (!isLt2M) {
        this.loadingImageFile = false;
        ElMessage.error((this as any).$t("popup.message.message_6e"));
      }

      return isLt2M && isJPG;
    },
  },
});
