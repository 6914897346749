
import { defineComponent } from "vue";
import { ElLoading } from "element-plus";
import _ from "lodash";

let loadingEl: any;

export default defineComponent({
  name: "MyProfile",
  computed: {
    paymentEnabled() {
      return process.env.VUE_APP_PAYMENT_ENABLED === "on";
    },
    user() {
      return this.$store.getters["user/user"];
    },
    loading() {
      return this.$store.getters["payment/loading"];
    },
  },
  watch: {
    loading(value: any) {
      if (value) {
        loadingEl = ElLoading.service({
          lock: true,
          text: "Login..",
          background: "#ffffff90",
        });
      } else {
        loadingEl.close();
      }
    },
    lang(value: string) {
      if (value == "en") {
        (this as any).$i18n.setLocale("en");
        this.lang = "en";
      } else if (value == "th") {
        (this as any).$i18n.setLocale("th");
        this.lang = "th";
      }
    },
  },
  async mounted() {
    if (!this.$store.getters["user/user"]) {
      await this.$store.dispatch("user/loadCurrentUser");
    }
    if (this.$store.getters["user/user"]) {
      this.color = this.$store.getters["user/user"].color;
    }
    const lang = localStorage.getItem("lang");
    if (lang == "EN") {
      (this as any).$i18n.setLocale("en");
      this.lang = "en";
    } else if (lang == "TH") {
      this.lang = "th";
    }
  },
  data() {
    return {
      lang: "en",
      userDetail: {
        id: "",
        firstname: "",
        lastname: "",
        company_name: "",
        company_color: "#000",
        logo: "",
        // link of image
        company_logo: "",
        imageFile: "",
      },
      color: "",
    };
  },
  methods: {
    async saveMyProfile() {
      const lang = this.lang;
      const setLocale = (lang: string) => {
        (this as any).$i18n.setLocale(lang);
      };
      setLocale(lang);
      localStorage.setItem("lang", lang.toUpperCase());

      const user = this.$store.getters["user/user"] || {};
      user.color = this.color;
      let userDetails: any = _.pickBy(user, (value, key) => {
        const pickedKeys = [
          "email",
          "firstName",
          "lastName",
          "companyName",
          "color",
        ];
        // return _.indexOf(pickedKeys, key) !== -1;
        const found = _.indexOf(pickedKeys, key) !== -1;
        const isEmpty = !value;
        return found && !isEmpty;
      });

      await this.$store.dispatch(
        "user/saveUser",
        { ...userDetails, ...{ lang } },
        {
          root: true,
        }
      );
    },
  },
});
